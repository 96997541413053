import React, { useState, useEffect } from "react";
import moment from "moment";
import * as clientCredential from "../../../Axios/Call/ClientCredential";
import * as authApi from "../../../Axios/Call/AuthApi";
import { Form } from "react-bootstrap";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import AlertGeneral from "../../../Components/Commons/AlertGeneral/AlertGeneral";
import LoadingButton from "../../../Components/Commons/LoadingButton/LoadingButton";
import Datetime from "react-datetime";
import {
  AiOutlineForm,
  AiFillCar,
  AiOutlinePlusCircle,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import { FaCalculator, FaParking } from "react-icons/fa";
import classes from "./CreateReservation.module.css";
import { useHistory } from "react-router-dom";

const CreateReservation = () => {
  const [departueDestination, setDepartueDestination] = useState("");
  const [returnDestination, setReturnDestination] = useState("");

  const [departues, setDepartues] = useState([]);
  const [places, setPlaces] = useState([]);
  const [timeArrival, setTimeArrival] = useState([]);
  const [timeDepartue, setTimeDepartue] = useState([]);
  const [departueDate, setDepartueDate] = useState(moment());
  const [returnDate, setReturnDate] = useState(moment().add(7, "days"));
  const [choosenDepartue, setChoosenDepartue] = useState({});
  const [choosenPlace, setChoosenPlace] = useState({});
  const [choosenTimeDepartue, setChoosenTimeDepartue] = useState({});
  const [choosenTimeArrival, setChoosenTimeArrival] = useState({});
  const [calculatedPrice, setCalculatedPrice] = useState({});
  const [additionalServices, setAdditionalServices] = useState([]);
  const [choosenAdditionalServices, setChoosenAdditionalService] = useState([]);
  const [plate, setPlate] = useState("");
  const [hasbaggage, setHasbaggage] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  //----Loading/Error/Success
  const [loadingDepartue, setLoadingDepartue] = useState(false);
  const [loadingPlace, setLoadingPlace] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [successApi, setSuccessApi] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [insurance, setInsurance] = useState(false);

  const [errorsForm, setErrorsForm] = useState({
    departueDate: false,
    returnDate: false,
    dateDiscrepancy: false,
    departue: false,
    place: false,
    plate: false,
    carKm: false,
    hasbaggage: false,
    time_departue: false,
    time_return: false,
  });
  const history = useHistory();

  useEffect(() => {
    setLoadingDepartue(true);
    clientCredential
      .departues()
      .then(response => {
        setDepartues(response.data);
        authApi
          .getAdditionalServicesClient()
          .then(response => {
            setAdditionalServices(response.data);
            setLoadingDepartue(false);
          })
          .catch(error => {
            setLoadingDepartue(false);
          });
      })
      .catch(error => {
        setLoadingDepartue(false);
      });
  }, []);

  useEffect(() => {
    if (Object.keys(choosenDepartue).length) {
      setErrorsForm({ ...errorsForm, departue: false });
      setLoadingPlace(true);
      setChoosenPlace({});
      clientCredential
        .places(choosenDepartue.id)
        .then(response => {
          setPlaces(response.data);
          setLoadingPlace(false);
          if (
            response.data.find(
              place => place.id === JSON.parse(localStorage.getItem("place")).id
            )
          ) {
            setChoosenPlace(
              localStorage.getItem("place")
                ? JSON.parse(localStorage.getItem("place"))
                : {}
            );
          }
        })
        .catch(error => {
          setLoadingPlace(false);
          setErrorApi(true);
        });
    }
  }, [choosenDepartue]);

  useEffect(() => {
    if (departueDate) {
      authApi
        .timeDepatue({ data: moment(departueDate).format("yyyy-MM-DD") })
        .then(response => {
          setTimeDepartue(response.data);
        })
        .catch(erorr => {});
    }
  }, [departueDate]);

  useEffect(() => {
    if (returnDate) {
      authApi
        .timeReturn({ data: moment(returnDate).format("yyyy-MM-DD") })
        .then(response => {
          setTimeArrival(response.data);
        })
        .catch(erorr => {});
    }
  }, [returnDate]);

  useEffect(() => {
    if (returnDate && departueDate && Object.keys(choosenDepartue).length) {
      let days = returnDate.diff(departueDate, "days")
        ? returnDate.diff(departueDate, "days")
        : 1;
      clientCredential
        .getCalculatedPrice({
          departue_id:
            choosenPlace === choosenDepartue ? choosenDepartue.id : 2,
          days: days,
        })
        .then(response => {
          setCalculatedPrice(response.data);
        })
        .catch(error => {});
    }
  }, [returnDate, departueDate, choosenDepartue]);

  const yesterday = moment().subtract(1, "day");
  const disablePastDt = current => {
    return current.isAfter(yesterday);
  };

  const hasNotGoodDay = (time /*Time is 00:00 - 00:15 */) => {
    if (
      //It delete the bed hours
      parseInt(moment().add(2, "hours").format("HH"), 10) >
        parseInt(time.substr(0, 2), 10) &&
      moment().format("DD/MM/yyyy") ===
        moment(departueDate)?.format("DD/MM/yyyy")
    ) {
      return true;
    }

    if (
      moment().format("DD/MM/yyyy") ===
        moment(departueDate)?.format("DD/MM/yyyy") && //same date departue as today
      parseInt(moment().format("HH"), 10) < 7 &&
      (parseInt(moment().format("HH"), 10) >= 0 ||
        parseInt(moment().format("HH"), 10) === 12 ||
        parseInt(moment().format("HH"), 10) === 24) &&
      parseInt(time.substr(0, 2), 10) < 12
    ) {
      return true;
    }
    return false;
  };

  const onChangeExtraOptionHandler = service => {
    if (
      choosenAdditionalServices.find(
        choosenService => choosenService.id === service.id
      )
    ) {
      let newChoosenAdditionalServices = [...choosenAdditionalServices];
      const index = newChoosenAdditionalServices.findIndex(
        choosenService => choosenService.id === service.id
      );
      newChoosenAdditionalServices.splice(index, 1);
      setChoosenAdditionalService(newChoosenAdditionalServices);
    } else {
      setChoosenAdditionalService(choosenAdditionalServices.concat(service));
    }
  };

  const renderPrice = () => {
    let basePrice = parseFloat(calculatedPrice?.total_our_price) || 0;

    choosenAdditionalServices?.forEach(choosenService => {
      basePrice += parseFloat(choosenService?.price) || 0;
    });

    let insuranceCost = 0;
    if (insurance) {
      const totalWithoutAdditionalServices =
        parseFloat(calculatedPrice?.total_our_price) || 0;
      const insurancePercentage = 0.1;
      insuranceCost =
        Math.ceil((totalWithoutAdditionalServices * insurancePercentage) / 5) *
        5;
    }

    const finalPrice = basePrice + insuranceCost;

    return finalPrice.toFixed(2);
  };

  const changeDetail = (text, type) => {
    if (type === "plate") {
      setPlate(text);
    }

    if (type === "departueDestination") {
      setDepartueDestination(text);
    }

    if (type === "returnDestination") {
      setReturnDestination(text);
    }

    if (type === "hasbaggage") {
      setHasbaggage(text);
    }
  };

  const placeMap = {
    1: "rennes-airport",
    2: "rennes-trainstation",
  };

  const createOrderHandler = () => {
    const formObj = errorsForm;
    if (!Object.keys(choosenDepartue).length) {
      setErrorsForm({ ...formObj, departue: true });
      return false;
    }
    if (!Object.keys(choosenPlace).length) {
      setErrorsForm({ ...errorsForm, place: true });
      return false;
    }
    if (!Object.keys(choosenTimeArrival).length) {
      setErrorsForm({ ...errorsForm, time_return: true });
      return false;
    }
    if (!Object.keys(choosenTimeDepartue).length) {
      setErrorsForm({ ...errorsForm, time_departue: true });
      return false;
    }
    if (
      moment(departueDate).add(1, "day") > moment(returnDate).add(1, "day") ||
      new Date() > moment(departueDate).add(1, "day")
    ) {
      setErrorsForm({ ...errorsForm, dateDiscrepancy: true });
      return false;
    }

    if (!plate) {
      setErrorsForm({ ...errorsForm, plate: true });
      return false;
    }

    if (!email) {
      setErrorsForm({ ...errorsForm, email: true });
      return false;
    }

    if (!name) {
      setErrorsForm({ ...errorsForm, name: true });
      return false;
    }
    setLoadingSubmit(true);

    authApi
      .createReservationAdmin({
        place_id: 1,
        departue_date_time: moment(departueDate).format("yyyy-MM-DD"),
        arrival_date_time: moment(returnDate).format("yyyy-MM-DD"),
        pay_amount: renderPrice() * 100,
        flight_number: "web (manuelle)",
        time_arrival_id: choosenTimeArrival.id,
        time_departue_id: choosenTimeDepartue.id,
        plate: plate,
        additional_servies: choosenAdditionalServices.map(service => {
          return { id: service.id };
        }),
        has_baggage: true,
        name: name,
        email: email,
        surname: surname,
        phone: phone,
        amount: renderPrice() * 100,
        departue_destination: departueDestination,
        arrival_destination: returnDestination,
        departue_place: placeMap[choosenDepartue.id],
        arrival_place: placeMap[choosenPlace.id],
        has_baggage: false,
        insurance: insurance,
      })
      .then(response => {
        setLoadingSubmit(false);
        setSuccessApi(true);
        setInterval(() => {
          history.push("/admin/requests");
        }, [3000]);
      })
      .catch(error => {
        setErrorApi(true);
        setLoadingSubmit(false);
      });
  };

  return (
    <>
      {/*errorApi && (
        <AlertGeneral
          color="danger"
          description="Error apis"
          setTrigger={setErrorApi}
        />
      )*/}
      {successApi && (
        <AlertGeneral
          color="success"
          description="Réservation enregistrée !"
          setTrigger={setSuccessApi}
        />
      )}
      {/*errorApi && (
        <AlertGeneral
          color="danger"
          description="Error loading clients"
          setTrigger={setErrorApi}
        />
      )*/}
      <Container fluid>
        <Row className="justify-content-center">
          <Col xs={12} lg={8}>
            <>
              <Row style={{ marginTop: "2rem" }}>
                <Col>
                  <span className={classes.title}>
                    Nouvelle réservation manuelle
                  </span>
                </Col>
              </Row>
              <Row style={{ marginTop: "3rem" }}>
                <Col xs={12} lg={4}>
                  {loadingDepartue ? (
                    <LoadingButton />
                  ) : (
                    <>
                      <Row>
                        <Col className="text-left">
                          <span className="labelStyleSteps">
                            LIEU DE PRISE EN CHARGE
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <DropdownButton
                            title={
                              Object.keys(choosenDepartue).length
                                ? choosenDepartue.name
                                : "Sélectionnez un lieu"
                            }
                            className={"dropDown"}
                          >
                            <Dropdown.Item key={0} disabled={true}>
                              <span>Sélectionnez un lieu</span>
                            </Dropdown.Item>
                            {departues.map(departue => {
                              return (
                                <Dropdown.Item
                                  key={departue.id}
                                  onSelect={() => setChoosenDepartue(departue)}
                                >
                                  <span>{departue.name}</span>
                                </Dropdown.Item>
                              );
                            })}
                          </DropdownButton>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col>
                      <span className="text-danger">
                        {errorsForm.departue
                          ? "Choisir le lieu de prise en charge"
                          : null}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} lg={4}>
                  <Row>
                    <Col className="text-left">
                      <span className="labelStyleSteps">DATE ALLER</span>
                    </Col>
                  </Row>
                  <Datetime
                    timeFormat={false}
                    onChange={event => {
                      setDepartueDate(event);
                      setChoosenTimeDepartue({});
                    }}
                    value={departueDate}
                    dateFormat={"D/M/Y"}
                    inputProps={{ className: "dateTimeStyle" }}
                    isValidDate={disablePastDt}
                  />

                  <Row>
                    <Col>
                      <span className="text-danger">
                        {errorsForm.dateDiscrepancy
                          ? "choisissez les bonnes dates"
                          : null}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} lg={4}>
                  <Row>
                    <Col className="text-left">
                      <span className="labelStyleSteps">
                        Prise en charge du véhicule
                      </span>
                    </Col>
                  </Row>
                  <DropdownButton
                    title={
                      Object.keys(choosenTimeDepartue).length
                        ? choosenTimeDepartue.name
                        : "Sélectionnez un horaire"
                    }
                    className={"dropDown"}
                  >
                    <Dropdown.Item key={0} disabled={true}>
                      <span>Sélectionnez un horaire</span>
                    </Dropdown.Item>
                    {timeDepartue?.map(time => {
                      return time.no_click || hasNotGoodDay(time.name) ? (
                        <Dropdown.Item
                          key={time.id}
                          onSelect={() => setChoosenTimeDepartue(time)}
                        >
                          <span>{time.name} ( complet )</span>
                        </Dropdown.Item>
                      ) : (
                        <>
                          <Dropdown.Item
                            key={time.id}
                            onSelect={() => setChoosenTimeDepartue(time)}
                          >
                            <span>{time.name}</span>
                          </Dropdown.Item>
                        </>
                      );
                    })}
                  </DropdownButton>
                  <Row>
                    <Col>
                      <span className="text-danger">
                        {errorsForm.time_departue
                          ? "choisissez l'horaire"
                          : null}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} lg={4}>
                  {loadingPlace ? (
                    <LoadingButton />
                  ) : (
                    <>
                      <Row>
                        <Col className="text-left">
                          <span className="labelStyleSteps">
                            PRISE EN CHARGE RETOUR
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <DropdownButton
                            title={
                              Object.keys(choosenPlace).length
                                ? choosenPlace.name
                                : "Sélectionnez un point"
                            }
                            className={"dropDown"}
                          >
                            <Dropdown.Item key={0} disabled={true}>
                              <span>Sélectionnez un point</span>
                            </Dropdown.Item>
                            {departues.map(place => {
                              return (
                                <>
                                  <Dropdown.Item
                                    key={place.id}
                                    onSelect={() => setChoosenPlace(place)}
                                  >
                                    <span>{place.name}</span>
                                  </Dropdown.Item>
                                </>
                              );
                            })}
                          </DropdownButton>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col>
                      <span className="text-danger">
                        {errorsForm.place
                          ? "Choisir point de prise en charge"
                          : null}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} lg={4}>
                  <Row>
                    <Col className="text-left">
                      <span className="labelStyleSteps">DATE RETOUR</span>
                    </Col>
                  </Row>
                  <Datetime
                    timeFormat={false}
                    onChange={event => {
                      setReturnDate(event);
                      setChoosenTimeArrival({});
                    }}
                    value={returnDate}
                    dateFormat={"D/M/Y"}
                    inputProps={{ className: "dateTimeStyle" }}
                    isValidDate={disablePastDt}
                  />
                </Col>
                <Col xs={12} lg={4}>
                  <Row>
                    <Col className="text-left">
                      <span className="labelStyleSteps">
                        Remise du véhicule
                      </span>
                    </Col>
                  </Row>
                  <DropdownButton
                    title={
                      Object.keys(choosenTimeArrival).length
                        ? choosenTimeArrival.name
                        : "Sélectionnez un horaire"
                    }
                    className={"dropDown"}
                  >
                    <Dropdown.Item key={0} disabled={true}>
                      <span>Sélectionnez un horaire</span>
                    </Dropdown.Item>
                    {timeArrival?.map(time => {
                      return time.no_click ? (
                        <Dropdown.Item
                          key={time.id}
                          onSelect={() => setChoosenTimeArrival(time)}
                        >
                          <span>{time.name} ( complet )</span>
                        </Dropdown.Item>
                      ) : moment()
                          .startOf("day")
                          .diff(moment(returnDate)?.startOf("day"), "days") ===
                        0 ? (
                        !time.no_click_today ? (
                          <Dropdown.Item
                            key={time.id}
                            onSelect={() => setChoosenTimeArrival(time)}
                          >
                            <span>{time.name}</span>
                          </Dropdown.Item>
                        ) : null
                      ) : (
                        <>
                          <Dropdown.Item
                            key={time.id}
                            onSelect={() => setChoosenTimeArrival(time)}
                          >
                            <span>{time.name}</span>
                          </Dropdown.Item>
                        </>
                      );
                    })}
                  </DropdownButton>
                  <Row>
                    <Col>
                      <span className="text-danger">
                        {errorsForm.time_return ? "choisissez l'horaire" : null}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
            {Object.keys(calculatedPrice).length && (
              <>
                <Row style={{ marginTop: "1rem" }}>
                  <Col xs={12} lg={4} className="my-auto">
                    <Row>
                      <Col className="text-left">
                        <Row>
                          <Col>
                            <span className="labelStyleSteps">
                              Tarif ARVIN CARE
                            </span>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: -10 }}>
                          <Col>
                            <span
                              className="labelStyleSteps"
                              style={{ fontSize: 10 }}
                            >
                              (parking + voiturier + assurance)
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card
                          style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            borderColor: "white",
                            backgroundColor: "#232D4C",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <AiFillCar color="white" />
                            <span
                              style={{
                                color: "white",
                                fontSize: 16,
                                fontWeight: "bolder",
                                paddingLeft: 10,
                              }}
                            >
                              {calculatedPrice.total_our_price &&
                                calculatedPrice.total_our_price.toFixed(2)}
                              {""}€
                            </span>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} lg={4} className="my-auto">
                    <Row>
                      <Col
                        className="text-left"
                        style={{ paddingBottom: "11px" }}
                      >
                        <span className="labelStyleSteps">
                          COÛT MOYEN DES PARKINGS LOCAUX
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card
                          style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            borderColor: "white",
                            backgroundColor: "#8F94A0",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FaParking color="white" />
                            <span
                              style={{
                                color: "white",
                                fontSize: 16,
                                paddingLeft: 10,
                              }}
                            >
                              {calculatedPrice.total_their_price &&
                                calculatedPrice.total_their_price.toFixed(
                                  2
                                )}{" "}
                              €
                            </span>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="my-auto">
                    <Row>
                      <Col
                        className="text-left"
                        style={{ paddingBottom: "11px" }}
                      >
                        <span className="labelStyleSteps">
                          ECONOMIE RÉALISÉE
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Card
                          style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            borderColor: "white",
                            backgroundColor: "#5F9EA0",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FaCalculator color="white" />
                            <span
                              style={{
                                color: "white",
                                fontSize: 16,
                                paddingLeft: 10,
                              }}
                            >
                              {calculatedPrice.percentage &&
                                calculatedPrice.percentage.toFixed(2)}{" "}
                              €
                            </span>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
            <>
              <Row style={{ marginTop: "2rem" }}>
                <Col>
                  <span style={{ fontSize: 28 }}>Détails</span>
                </Col>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Col xs={12} lg={6}>
                  <Row>
                    <Col className="text-left">
                      <span className="labelStyleSteps">Email</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-left">
                      <input
                        value={email}
                        className="inputStyle"
                        onChange={event => setEmail(event.target.value)}
                        placeholder={""}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                  {errorsForm.email && (
                    <Row>
                      <Col>
                        <span className="text-danger">Email du</span>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col xs={12} lg={6}>
                  <Row>
                    <Col className="text-left">
                      <span className="labelStyleSteps">Prénom</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-left">
                      <input
                        value={name}
                        className="inputStyle"
                        onChange={event => setName(event.target.value)}
                        placeholder={""}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                  {errorsForm.name && (
                    <Row>
                      <Col>
                        <span className="text-danger">Prénom et nom</span>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col xs={12} lg={6}>
                  <Row>
                    <Col className="text-left">
                      <span className="labelStyleSteps">Téléphone</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-left">
                      <input
                        value={phone}
                        className="inputStyle"
                        onChange={event => setPhone(event.target.value)}
                        placeholder={""}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                  {errorsForm.phone && (
                    <Row>
                      <Col>
                        <span className="text-danger">Numéro de portable</span>
                      </Col>
                    </Row>
                  )}
                </Col>
                <Col xs={12} lg={6}>
                  <Row>
                    <Col className="text-left">
                      <span className="labelStyleSteps">Nom de famille</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-left">
                      <input
                        value={surname}
                        className="inputStyle"
                        onChange={event => setSurname(event.target.value)}
                        placeholder={""}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                  {errorsForm.surname && (
                    <Row>
                      <Col>
                        <span className="text-danger">Entrez votre nom</span>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>

              <Row style={{ marginTop: 20 }}>
                <Col xs={12} lg={6}>
                  <Row>
                    <Col className="text-left">
                      <span className="labelStyleSteps">Immatriculation</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-left">
                      <input
                        value={plate}
                        className="inputStyle"
                        onChange={event => setPlate(event.target.value)}
                        placeholder={""}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                  {errorsForm.plate ? (
                    <Row>
                      <Col>
                        <span className="text-danger">
                          Insérer Plaque d'immatriculation
                        </span>
                      </Col>
                    </Row>
                  ) : null}
                </Col>
                <Col xs={12} lg={6}>
                  <Row className={"rowStyle"}>
                    <Col className="text-left">
                      <span className="labelStyleSteps">
                        Votre destination à l'aller
                      </span>
                    </Col>
                  </Row>

                  <Row className={"rowStyle"}>
                    <Col className="text-left">
                      <input
                        value={departueDestination}
                        className="inputStyle"
                        onChange={event =>
                          changeDetail(
                            event.target.value,
                            "departueDestination"
                          )
                        }
                        placeholder={"ex : Paris"}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                  {errorsForm.departueDestination ? (
                    <Row className={"rowStyle"}>
                      <Col>
                        <span className="text-danger">
                          Veuillez renseigner votre destination
                        </span>
                      </Col>
                    </Row>
                  ) : null}
                </Col>

                <Col xs={12} lg={6}>
                  <Row className={"rowStyle"}>
                    <Col className="text-left">
                      <span className="labelStyleSteps">
                        Provenance au retour
                      </span>
                    </Col>
                  </Row>

                  <Row className={"rowStyle"}>
                    <Col className="text-left">
                      <input
                        value={returnDestination}
                        className="inputStyle"
                        onChange={event =>
                          changeDetail(event.target.value, "returnDestination")
                        }
                        placeholder={"ex : Marbella"}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                  {errorsForm.returnDestination ? (
                    <Row className={"rowStyle"}>
                      <Col>
                        <span className="text-danger">
                          Veuillez renseigner votre provenance au retour
                        </span>
                      </Col>
                    </Row>
                  ) : null}
                </Col>

                <Row className={"rowStyle"} style={{ padding: 30 }}>
                  <Col className="text-left">
                    <Form.Check
                      type="switch"
                      id="insurance-toggle"
                      label="Assurance annulation"
                      checked={insurance}
                      onChange={() => setInsurance(!insurance)}
                    />
                  </Col>
                </Row>
              </Row>
              <Row style={{ marginTop: "3rem" }}>
                <Col>
                  <span className={classes.title}>
                    Services supplémentaires
                  </span>
                </Col>
              </Row>
              <Row style={{ marginTop: "2rem" }}>
                <Col xs={12} lg={{ span: 10, offset: 1 }}>
                  <Row>
                    {additionalServices.map(service => {
                      return (
                        <Col xs={12} lg={3} style={{ marginTop: 10 }}>
                          <Card
                            style={{
                              borderRadius: 20,
                              paddingBottom: 10,
                              height: "250px",
                              width: "auto",
                            }}
                          >
                            <Image
                              src={"data:image/png;base64," + service.base64}
                              style={{
                                width: "auto",
                                height: "130px",
                                borderTopRightRadius: 20,
                              }}
                              className={classes.image}
                            />
                            <span
                              style={{
                                marginTop: 10,
                                color: "#1C222D",
                                fontWeight: "bold",
                                fontSize: 16,
                              }}
                            >
                              {service.name}
                            </span>
                            <Row>
                              <Col className="my-auto">
                                <span
                                  style={{
                                    marginTop: 10,
                                    color: "#1C222D",
                                    fontWeight: "bold",
                                    fontSize: 18,
                                  }}
                                >
                                  {service.price} €
                                </span>
                              </Col>
                              <Col>
                                {!choosenAdditionalServices.find(
                                  choosenService =>
                                    choosenService.id === service.id
                                ) ? (
                                  <AiOutlinePlusCircle
                                    size={40}
                                    onClick={() =>
                                      onChangeExtraOptionHandler(service)
                                    }
                                  />
                                ) : (
                                  <AiOutlineCheckCircle
                                    size={40}
                                    color={"green"}
                                    onClick={() =>
                                      onChangeExtraOptionHandler(service)
                                    }
                                  />
                                )}
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
              <Row style={{ marginTop: 50 }}>
                <Col>
                  <span className={classes.title}>
                    Prix total: {renderPrice()} €
                  </span>
                </Col>
              </Row>
              <Row style={{ marginTop: "2rem", marginBottom: "1rem" }}>
                <Col>
                  {loadingSubmit ? (
                    <LoadingButton />
                  ) : (
                    <Button
                      className="buttonGeneralStyle"
                      onClick={() => createOrderHandler()}
                    >
                      Valider
                    </Button>
                  )}
                </Col>
              </Row>
            </>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateReservation;
